import * as React from "react";
import Img, { FluidObject } from "gatsby-image";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import { Body } from "@quandoo-dev/cookbook/lib/typography";


interface ImageProps {
  description: string;
  caption?: string;
  asset: { fluid: FluidObject };
}

const Image: React.FC<ImageProps> = ({
  description,
  caption,
  asset: { fluid },
}) => {
  return (
    <Box as="figure" m="0" mb="md" borderRadius="md">
      <Img alt={description} fluid={fluid} />
      {caption && (
        <Body as="figcaption" mt="xs" size="xs">
          {caption}
        </Body>
      )}
    </Box>
  );
};

export default Image;
