import * as React from "react";
import { graphql } from "gatsby";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import Layout from "../components/layout";
import Breadcrumbs, { Crumb } from "../components/breadcrumbs";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import FeatureImage from "../components/post/feature-image";
import MetaInfo from "../components/post/meta-info";
import RichText from "../components/rich-text";
import Image from "../components/post/image";
import MerchantEmbed from "../components/merchant-embed";
import Tags from "../components/post/tags";
import Share from "../components/post/share";
import { Helmet } from "react-helmet";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import PostInRow from "../components/cards/postInRow";
import styled from "styled-components";
import useTranslation from "../hooks/useTranslation";
import { formatDate } from "../utils/format-date";
import { getOneTrustId } from '../utils/one-trust-ids';

const DesktopHeroImage = styled(FeatureImage)`
  display: none;
  @media (min-width: 1024px) {
    object-fit: cover;
    display: block;
    height: 380px;
  }
`;

const MobileHeroImage = styled(FeatureImage)`
  display: block;
  @media (min-width: 1024px) {
    object-fit: cover;
    display: none;
    height: 368px;
  }
`;

interface BlogProps {
  data: any;
}

const BlogPost: React.FC<BlogProps> = props => {
  const {
    data: {
      contentfulPost: {
        heroDesktop,
        heroMobile,
        slug,
        author,
        publishDate,
        updatedAt,
        timeToRead,
        title,
        description: { description: description },
        content,
        tags,
        category,
        locale,
        relatedPosts,
      },
    },
  } = props;

  const crumbs = [
    {
      // TODO: the string for "Home" should come from Contentful according to locale
      name: "Home",
      to: `/`,
      item: `${locale.domain}`,
    },
    {
      name: category.name,
      to: `/${category.slug}`,
      item: `${locale.domain}/${category.slug}`,
    } as Crumb,
    {
      name: title,
      item: `${locale.domain}/${category.slug}/${slug}`,
    } as Crumb,
  ];

  const staffPicks = relatedPosts.map((post, index) => (
    <Grid.Item columns={[12, 12, 6, 3]} key={index}>
      <PostInRow
        post={post}
        staticImage={null}
        hideDivider={relatedPosts.length - 1 == index}
      ></PostInRow>
    </Grid.Item>
  ));

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${locale.domain}/${category.slug}/${slug}`,
    },
    headline: title,
    image: heroDesktop.asset.fluid.src,
    author: {
      "@type": "person",
      name: author.firstName,
    },
    datePublished: publishDate,
    dateModified: updatedAt,
    description: description,
    publisher: {
      "@type": "Organization",
      name: "Quandoo",
      url: locale.domain,
      logo: {
        "@type": "ImageObject",
        url: `https:${locale.logo.fluid.src}`,
      },
    },
  };

  return (
    <Layout locale={locale.slug}>
      <Helmet
        htmlAttributes={{
          lang: locale.languageCode,
        }}
        title={title + " | " + locale.name}
        titleTemplate={title + " | " + locale.name}
        link={[
          {
            rel: "canonical",
            key: `${locale.domain}/${category.slug}/${slug}`,
            href: `${locale.domain}/${category.slug}/${slug}`,
          },
        ]}
        meta={[
          {
            name: `description`,
            content: description,
          },
          { name: `robots`, content: `index, follow` },
          { name: `og:locale`, content: locale.languageCode },
          { name: `og:type`, content: `article` },
          { name: `og:title`, content: title + " | " + locale.name },
          {
            name: `og:description`,
            content: description,
          },
          { name: `og:site_name`, content: locale.name },
          {
            name: `og:url`,
            content: `${locale.domain}/${category.slug}/${slug}`,
          },
          { name: `og:image`, content: heroDesktop.asset.fluid.src },
          { name: `twitter:card`, content: heroDesktop.asset.fluid.src },
          { name: `twitter:title`, content: title + " | " + locale.name },
          { name: `twitter:site`, content: "@" + locale.twitterAccountName },
          {
            name: `twitter:description`,
            content: description,
          },
          { name: `twitter:image`, content: heroDesktop.asset.fluid.src },
          {
            name: `article:publisher`,
            content: "https://www.facebook.com/quandoo." + locale.slug,
          },
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        {locale.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(locale.slug)} ></script>}
        {locale.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Grid.Item columns={12}>
        <Breadcrumbs crumbs={crumbs} />
        <MobileHeroImage
          alt={heroMobile.description}
          fluid={heroMobile.asset.fluid}
          style={{ borderRadius: "8px" }}
        />
        <DesktopHeroImage
          alt={heroDesktop.description}
          fluid={heroDesktop.asset.fluid}
          style={{ borderRadius: "8px" }}
        />
      </Grid.Item>
      <Box
        maxWidth={{ _: "auto", md: "770px", lg: "800px" }}
        mx="auto"
        mt={["27px", "43px", "35px", "35px"]}
        as="article"
        mb="xxxl"
      >
        <MetaInfo
          author={author}
          publishDate={formatDate(locale.languageCode, publishDate)}
          timeToRead={timeToRead}
          staticImage={null}
          country={locale.slug}
        />
        <Heading size={1} mt={["24px", "24px", "33px", "33px"]} mb="md">
          {title}
        </Heading>
        <Body size={["lg", "lg", "lg", "xl"]} mb="lg">
          {}
          <RichText
            key="description"
            document={description.json}
            domain={locale.domain}
          />
        </Body>
        {content.map(c => {
          switch (c.__typename) {
            case "ContentfulPostCopy":
              return (
                <RichText
                  key={c.contentful_id}
                  document={c.richBody.json}
                  domain={locale.domain}
                />
              );
            case "ContentfulImage":
              return (
                <Image
                  key={c.contentful_id}
                  {...c}
                />
              );
            case "ContentfulPostMerchantEmbed":
              return (
                <MerchantEmbed
                  key={c.contentful_id}
                  {...c}
                  staticImage={null}
                  country={locale.slug}
                />
              );
            case "ContentfulTopicPostEmbed":
              const markup = { __html: c.code.code };
              return <div dangerouslySetInnerHTML={markup}></div>;
            default:
              return null;
          }
        })}
      </Box>
      <Box maxWidth={{ _: "auto", md: "770px", lg: "800px" }} mx="auto" pt="lg">
        {tags?.length && <Tags tags={tags} country={locale.slug} />}
        <Box mt="xl">
          <Share
            locale={locale}
            title={title}
            description={description}
            url={`${locale.domain}/${category.slug}/${slug}`}
            country={locale.slug}
          />
        </Box>
        {/* <Newsletter /> */}
      </Box>
      <Grid.Row mb="xxxl">
        <Grid.Item columns={12}>
          <Heading size={4} color="secondary" mt="xxxl">
            {useTranslation(locale.slug, "post.related_posts")}
          </Heading>
          <Divider color="#D8D8D8" mt="xxs" mb="xs" />
        </Grid.Item>
        {staffPicks}
      </Grid.Row>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $localeSlug: String!) {
    contentfulPost(slug: { eq: $slug }, locale: { slug: { eq: $localeSlug } }) {
      title
      slug
      updatedAt
      publishDate
      timeToRead
      relatedPosts {
        slug
        title
        previewImage {
          description
          asset {
            fluid(maxWidth: 536) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
        description {
          description
        }
        category {
          name
          slug
        }
        locale {
          slug
        }
        timeToRead
      }
      description {
        description
      }
      locale {
        name
        slug
        domain
        languageCode
        twitterAccountName
        logo {
          fluid {
            src
          }
        }
      }
      category {
        name
        locale {
          name
          slug
        }
        slug
      }
      author {
        firstName
        lastName
        slug
        locale {
          slug
        }
        profileImage {
          fixed(width: 40) {
            width
            height
            src
            srcSet
          }
        }
      }
      heroDesktop {
        description
        asset {
          fluid(maxWidth: 1216) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
      heroMobile {
        description
        asset {
          fluid(maxWidth: 652) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
      tags {
        name
        locale {
          name
          slug
        }
        slug
      }
      content {
        __typename
        ... on Node {
          ... on ContentfulPostCopy {
            contentful_id
            richBody {
              json
            }
          }
          ... on ContentfulImage {
            contentful_id
            description
            caption
            asset {
              fluid(maxWidth: 799) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
          ... on ContentfulTopicPostEmbed {
            contentful_id
            code {
              code
            }
          }
          ... on ContentfulPostMerchantEmbed {
            contentful_id
            merchantName
            richDescription {
              json
            }
            instagramPostUrl
            merchantImage {
              fluid(maxWidth: 799) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            priceCategory
            linkToBook
            address {
              lon
              lat
            }
            addressText
            openingHours {
              day
              open
              close
            }
            ctaText
            customSectionTitle
            customSectionText {
              json
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
