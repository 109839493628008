// custom format date function
export const formatDate = (languageCode, date) => {
  const formattedDate =
    languageCode == "en-gb" ||
    languageCode == "en-sg" ||
    languageCode == "en-au"
      ? new Date(date).toLocaleString("en-AU", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })
      : new Date(date).toLocaleString(languageCode, {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
  return languageCode == "it-it"
    ? italianizeDate(formattedDate)
    : formattedDate;
};

// convert date format 'dd month yyyy' => 'dd. Month yyyy'
const italianizeDate = date => {
  const dateSplitted = date.split(" ");
  return `${dateSplitted[0]}. ${dateSplitted[1].charAt(0).toUpperCase() +
    dateSplitted[1].slice(1)} ${dateSplitted[2]}`;
};
